import React from "react";
import header from './Media/Heading-02.jpg'
import './App.css'

const App = () => {


  return (
    <div>
      <div className="headerImage">
        <a href="https://zamatrack.co.za/" target="_blank" rel="noreferrer">
          <img  src={header} width={1360} 
          alt="zamatrack"
          />
        </a>
      </div>
      <div className="contactUs">
      <div className="column1">
    <h3>Johannesburg, Cape Town</h3>
      <div>JHB: 081 744 1707</div>
      <div>CPT: 081 576 0239</div>
      <div>sales@zamatrack.co.za</div>
      <div>The Work Space, 1 Maxwell Dr, Sandton, Midrand, 2191</div>
    </div>
    <div className="column2">
      <h3>Durban</h3>
      <div>DBN: 068 115 6803</div>
      <div>sales@zamatrack.co.za</div>
      <div>16 Almil Centre 47 Ashley avenue Glenashley, 4051 Durban North</div>
    </div>
    </div>
    </div>
  );
};

export default App;
